@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";

.container {
  display: flex;
  flex-wrap: wrap;

  section.leftSection {
    margin: 2rem;
    width: calc(80% - 4rem);
  }

  section.rightSection {
    margin: 2rem;
    width: calc(20% - 4rem);
  }

  button {
    width: 30rem;
  }

  .selectAllSwitch {
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 30rem;
    padding: 1rem;
    border-radius: 1rem;
  }
}

:global #preloader {
  width: 5rem;
  margin: auto;
}

.label {
  @include font-regular;

  display: flex;
  align-items: center;
  padding: $spacing-eight 0;
  font-size: 1.6rem;
  cursor: pointer;

  &.disabled {
    color: $color-text-primary-disabled;
    cursor: not-allowed;
  }

  .checkbox {
    accent-color: $color-checkbox-accent-checked;
    margin: 0 $spacing-sixteen;
    width: 1.8rem;
    height: 1.8rem;
    flex-shrink: 0;
  }
}

.sort-and-filter {
  display: flex;
  gap: $spacing-thirtytwo;
  align-items: center;
}
