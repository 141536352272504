@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/variables/z-index";

$sub-nav-color: #8c8b8a;

.item {
  @include text-14-medium;

  display: flex;
  flex-direction: column;
  color: $color-web-header-text-default;
  fill: $color-web-header-text-default;
  text-decoration: none;
  height: 100%;
  border-top: 0.1rem solid $color-grey-light;
  position: relative;

  @include breakpoint-large-up {
    flex-direction: row;
    align-items: center;
    text-align: center;
    border: 0;
    position: relative;
    padding: 0 0.4rem;
  }

  &:hover {
    background-color: $color-web-header-background-hovered;
  }

  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }

  svg path {
    fill: $color-button-primary-outline-contents-default;
  }

  &.active {
    @include font-semi-bold;

    color: $color-web-header-text-selected;
    fill: $color-web-header-selection-indicator;

    &::after {
      content: "";
      width: 100%;
      height: 0.3rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $color-web-header-selection-indicator;
    }

    svg path {
      stroke: $color-web-header-selection-indicator;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .newLabel {
    background-color: $color-web-header-new-label-background-default;
    float: right;
    padding: 3px 15px;
    color: white;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: -3px;
  }
}

.icon {
  border: none;
  padding: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 1.5rem;

  @include breakpoint-large-up {
    margin-right: 0;
    width: 2.6rem;
    height: 2.6rem;
  }
}

.adTitle {
  flex-grow: 0;
  color: $color-grey-medium;
  font-size: 1.2rem;
  text-transform: uppercase;

  @include breakpoint-large-up {
    position: absolute;
    top: 0.8rem;
    left: 1rem;
  }
}

.subNavItem {
  margin: 0;
  font-size: 1.4rem;
  background-color: $color-web-header-background-default;

  &:hover {
    background-color: $color-web-header-background-hovered;
  }

  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }

  &.active {
    @include font-semi-bold;
  }

  .newLabel {
    background-color: $color-web-header-new-label-background-default;
    float: right;
    padding: 3px 15px;
    color: white;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: -3px;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  padding: 1.8rem;

  @include breakpoint-large-up {
    flex-direction: column;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  }
}

.navButton {
  @include text-14-medium;

  position: relative;
  padding-right: 2rem;
  box-shadow: none;
  border: none;
  background-color: transparent;
  font-size: 1.4rem;
  color: inherit;

  @include breakpoint-large-up {
    background-color: transparent !important;
    position: relative;
    display: block;
    padding: 4.2rem 2.7rem 1.4rem 1.3rem;
  }

  &::after {
    content: "";
    position: absolute;
    right: 1.5rem;
    bottom: 50%;
    margin-bottom: -0.2rem;
    border-color: inherit;
    border-right: 0.2rem solid $color-web-header-text-default;
    border-bottom: 0.2rem solid $color-web-header-text-default;
    width: 0.6rem;
    height: 0.6rem;
    transform: translateY(0.3rem) rotate(45deg);

    @include breakpoint-large-up {
      bottom: 1.9rem;
      right: 1rem;
      margin-bottom: 0;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    color: inherit;
  }

  .icon {
    @include breakpoint-large-up {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 1rem;
    }
  }
}

.navButtonExpanded {
  background-color: $color-web-header-background-hovered !important;

  &::after {
    transform: translateY(0.3rem) rotate(-135deg);
  }
}

.activeNavButton {
  @include font-semi-bold;

  color: $color-web-header-text-selected;
  fill: $color-web-header-icon-selected;

  &::after {
    border-color: $color-web-header-text-selected;
  }

  &::before {
    content: "";
    width: 100%;
    height: 0.3rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $color-web-header-icon-selected;
  }

  &:active {
    color: $color-web-header-text-selected;
  }
}

.subNav {
  display: none;
  padding: 0;
}

.subNavExpanded {
  display: block;

  @include breakpoint-large-up {
    position: absolute;
    top: 100%;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.16);
    border: 0.1rem solid $color-web-header-background-hovered;
    left: 0;
    min-width: 18.8rem;
    z-index: $z-index-second;
  }
}

.subNavItemCTA {
  @include font-regular;

  display: block;
  color: $color-web-header-text-default;
  padding: 1rem 1.5rem;
  text-decoration: none;
  text-align: left;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  width: 100%;

  @include breakpoint-large-up {
    padding: 1rem;
  }

  &:hover {
    text-decoration: none;
    background-color: $color-web-header-background-hovered;
  }

  &:focus {
    &:not(:focus-visible) {
      outline: none;
    }
  }
}

.loginButton {
  @include font-semi-bold;

  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.8rem;
  height: 3.2rem;
  padding: 0.5rem $spacing-sixteen;
  border: none;
  color: $color-button-secondary-contents-default;
  background-color: $color-button-secondary-background-default;
  transition: background-color 80ms ease-out;

  @include breakpoint-large-up {
    padding: 1.1rem 2.4rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    border-radius: 1.2rem;
    height: 100%;
  }

  &:hover {
    color: $color-button-secondary-contents-hovered;
    background-color: $color-button-secondary-background-hovered;
  }
}

.loginButtonIcon {
  circle,
  path {
    stroke: $color-button-secondary-contents-default;
  }

  @include breakpoint-medium-up {
    display: none;
  }
}

.loginButtonText {
  display: none;

  @include breakpoint-medium-up {
    display: block;
  }
}

.navItemLogin:hover {
  background-color: transparent;
}

.adAlignment {
  justify-content: space-between;
}

.navItemButton {
  @include breakpoint-large-up {
    padding: 0;
  }
}

.noIcon {
  @include breakpoint-large-up {
    padding: 2.8rem 3rem 2.8rem 1rem;
  }

  &::after {
    @include breakpoint-large-up {
      bottom: 50%;
      transform: translateY(0.3rem) rotate(45deg);
    }
  }

  &.navButtonExpanded {
    &::after {
      transform: translateY(0.6rem) rotate(-135deg);
    }
  }
}

.userButton {
  @include breakpoint-large-up {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 13rem;
  }
}

.userItem {
  @include breakpoint-large-up {
    margin-left: 2rem;
    left: auto !important;
    right: 0;
    width: max-content;
  }
}
