$product: "no";

$color-primary: #ffbb00;

$color-grey-darker: #313030;
$color-grey-dark: #8c8b8a;
$color-grey-medium: #aaa;
$color-grey-light: #d8d8d8;

/* Color styles */
$color-core-primary-dark-1: #e4a905ff;
$color-core-primary: #ffbb00ff;
$color-core-primary-light-1: #fdd072ff;
$color-core-primary-light-2: #fedd9aff;
$color-core-primary-light-3: #feeeccff;
$color-core-secondary-dark-1: #168016ff;
$color-core-secondary: #55bb55ff;
$color-core-secondary-light-1: #77c977ff;
$color-core-secondary-light-2: #99d699ff;
$color-core-secondary-light-3: #ddf1ddff;
$color-core-orange: #ff9324ff;
$color-core-salmon: #ff806cff;
$color-core-red: #ce2b1dff;
$color-core-black: #000000ff;
$color-core-grey-dark-1: #373737ff;
$color-core-logo-grey: #545454ff;
$color-core-grey: #757677ff;
$color-core-grey-light-1: #b2b2b2ff;
$color-core-grey-light-2: #c6c6c6ff;
$color-core-grey-light-3: #d9d9d9ff;
$color-core-grey-light-4: #edededff;
$color-core-grey-light-5: #f4f4f4ff;
$color-core-white: #ffffffff;
$color-background-light-translucent: #ffffffa6;
$color-background-light-solid: #ffffffff;
$color-background-color-default: #fedd9aff;
$color-background-color-secondary: #f4f4f4ff;
$color-button-primary-background-default: #99d699ff;
$color-button-primary-background-pressed: #55bb55ff;
$color-button-primary-background-hovered: #77c977ff;
$color-button-primary-background-disabled: #edededff;
$color-button-primary-contents-default: #373737ff;
$color-button-primary-contents-pressed: #373737ff;
$color-button-primary-contents-hovered: #373737ff;
$color-button-primary-contents-disabled: #b2b2b2ff;
$color-button-primary-outline-contents-default: #373737ff;
$color-button-primary-outline-contents-pressed: #373737ff;
$color-button-primary-outline-contents-hovered: #373737ff;
$color-button-primary-outline-contents-disabled: #757677ff;
$color-button-primary-outline-border-pressed: #99d699ff;
$color-button-primary-outline-border-default: #99d699ff;
$color-button-primary-outline-border-hovered: #99d699ff;
$color-button-primary-outline-border-disabled: #d9d9d9ff;
$color-button-primary-outline-background-pressed: #99d699ff;
$color-button-primary-outline-background-hovered: #ddf1ddff;
$color-button-secondary-contents-default: #373737ff;
$color-button-secondary-contents-disabled: #757677ff;
$color-button-secondary-contents-pressed: #373737ff;
$color-button-secondary-contents-hovered: #373737ff;
$color-button-secondary-background-default: #99d699ff;
$color-button-secondary-background-pressed: #55bb55ff;
$color-button-secondary-background-hovered: #77c977ff;
$color-button-secondary-background-disabled: #d9d9d9ff;
$color-button-secondary-outline-border-default: #99d699ff;
$color-button-secondary-outline-border-hovered: #99d699ff;
$color-button-secondary-outline-border-disabled: #edededff;
$color-button-secondary-outline-border-pressed: #99d699ff;
$color-button-secondary-outline-contents-default: #373737ff;
$color-button-secondary-outline-contents-pressed: #373737ff;
$color-button-secondary-outline-contents-hovered: #373737ff;
$color-button-secondary-outline-contents-disabled: #b2b2b2ff;
$color-button-secondary-outline-background-pressed: #99d699ff;
$color-button-secondary-outline-background-hovered: #ddf1ddff;
$color-button-navigation-background-default: #fedd9aff;
$color-button-navigation-background-pressed: #ffbb00ff;
$color-button-navigation-background-hovered: #fdd072ff;
$color-button-navigation-background-disabled: #edededff;
$color-button-navigation-contents-default: #373737ff;
$color-button-navigation-contents-pressed: #373737ff;
$color-button-navigation-contents-hovered: #373737ff;
$color-button-navigation-contents-disabled: #b2b2b2ff;
$color-button-card-border-default: #55bb55ff;
$color-button-card-background-default: #ffffffff;
$color-button-log-text-default: #373737ff;
$color-button-log-icon-checked: #55bb55ff;
$color-button-log-icon-default: #55bb55ff;
$color-button-illustrated-illustration-background: #f4f4f4ff;
$color-button-illustrated-illustration-contents: #000000ff;
$color-button-card-navigation-contents: #168016ff;
$color-button-icon-button-contents: #757677ff;
$color-action-navigation: #168016ff;
$color-action-icon-default: #168016ff;
$color-action-icon-disabled: #00000033;
$color-action-icon-secondary: #55bb55ff;
$color-action-icon-tertiary: #757677ff;
$color-text-button-contents-default: #168016ff;
$color-text-button-contents-secondary: #000000ff;
$color-text-primary: #000000ff;
$color-text-hint: #757677ff;
$color-text-secondary: #757677ff;
$color-text-tertiary: #b2b2b2ff;
$color-text-error: #ce2b1dff;
$color-text-primary-inversed: #ffffffff;
$color-text-primary-disabled: #b2b2b2ff;
$color-status-tag-info-contents: #000000ff;
$color-status-tag-info-background: #d9d9d9ff;
$color-status-tag-label-background: #545454ff;
$color-status-tag-label-contents: #ffffffff;
$color-status-tag-alert-contents: #000000ff;
$color-status-tag-alert-background: #feeeccff;
$color-status-tag-weight-content: #000000ff;
$color-status-tag-weight-background: #ffffffff;
$color-tag-clickable-contents-default: #000000ff;
$color-tag-clickable-contents-disabled: #b2b2b2ff;
$color-tag-clickable-background-disabled: #edededff;
$color-tag-clickable-background-selected: #fedd9aff;
$color-tag-clickable-border-default: #c6c6c6ff;
$color-tag-recipe-contents: #000000ff;
$color-tag-recipe-background: #fedd9aff;
$color-tag-label-content: #373737ff;
$color-tag-label-background: #fedd9aff;
$color-tag-new-content: #373737ff;
$color-tag-new-background: #fdd072ff;
$color-tag-content-card-editorial-content: #000000ff;
$color-tag-content-card-editorial-background: #fedd9aff;
$color-tag-content-card-program-content: #000000ff;
$color-tag-content-card-program-background: #fedd9aff;
$color-tag-tooltip-content: #ffffffff;
$color-tag-tooltip-background: #e4a905ff;
$color-tag-web-clickable-contents-default: #373737ff;
$color-tag-web-clickable-contents-disabled: #b2b2b2ff;
$color-tag-web-clickable-border-default: #c6c6c6ff;
$color-accent-icon: #000000ff;
$color-accent-default: #ffbb00ff;
$color-accent-log: #55bb55ff;
$color-accent-progress-indicator: #ffbb00ff;
$color-accent-check: #000000ff;
$color-accent-feedback-positive: #168016ff;
$color-accent-feedback-negative: #ff806cff;
$color-accent-getting-started-icon: #373737ff;
$color-accent-getting-started-icon-background: #ffffffff;
$color-accent-partial-goal-achieved: #55bb55ff;
$color-accent-partial-goal-ongoing: #ffbb00ff;
$color-accent-partial-goal-remaining: #b2b2b2ff;
$color-dropdown-border-expanded: #fedd9aff;
$color-dropdown-border-default: #b2b2b2ff;
$color-dropdown-background-default: #ffffffff;
$color-text-field-background-default: #ffffffff;
$color-text-field-border-default: #b2b2b2ff;
$color-text-field-border-focused: #fedd9aff;
$color-text-field-border-dark: #757677ff;
$color-search-field-i-os-icons: #757677ff;
$color-search-field-background-default: #ffffffff;
$color-bottom-navigation-accent: #000000ff;
$color-bottom-navigation-default: #757677ff;
$color-bottom-navigation-divider: #edededff;
$color-bottom-navigation-hovered: #000000ff;
$color-progress-bar-background-default: #ffffffff;
$color-progress-bar-background-secondary: #edededff;
$color-progress-bar-background-story: #ffffff99;
$color-progress-bar-background-tertiary: #c6c6c6ff;
$color-progress-bar-check-content: #ffffffff;
$color-progress-bar-check-background: #fab701ff;
$color-progress-bar-progress-default: #fab701ff;
$color-progress-bar-progress-story-dark: #37373780;
$color-progress-bar-progress-story-light: #ffffffff;
$color-macronutrients-fat: #ffa84eff;
$color-macronutrients-protein: #88cf88ff;
$color-macronutrients-carbohydrates: #fdd072ff;
$color-macronutrients-alcohol: #ff806cff;
$color-macronutrients-energy: #545454ff;
$color-info-toast-background: #00000080;
$color-web-floating-background: #f4f4f4ff;
$color-web-pill-tab-contents-default: #373737ff;
$color-web-pill-tab-contents-selected: #373737ff;
$color-web-pill-tab-background-hovered: #edededff;
$color-web-pill-tab-background-default: #ffffffff;
$color-web-pill-tab-background-selected: #fedd9aff;
$color-web-underline-tab-contents-default: #373737ff;
$color-web-underline-tab-contents-selected: #373737ff;
$color-web-underline-tab-underline-selected: #ffbb00ff;
$color-web-underline-tab-underline-default: #c6c6c6ff;
$color-web-underline-tab-background-hovered: #edededff;
$color-web-expand-button-contents: #168016ff;
$color-web-switch-thumb-checked: #ffffffff;
$color-web-switch-track-checked: #55bb55ff;
$color-web-switch-track-default: #ffffffff;
$color-web-switch-tumb-default: #ffffffff;
$color-web-divider-primary: #d9d9d9ff;
$color-web-divider-secondary: #b2b2b2ff;
$color-web-modal-header-background: #ffffffff;
$color-web-background-section: #feeeccff;
$color-web-background-secondary: #f4f4f4ff;
$color-web-background-default: #ffffffff;
$color-web-background-tertiary: #f4f4f4ff;
$color-web-button-log-text-default: #000000ff;
$color-web-button-filter-background-default: #edededff;
$color-web-button-filter-background-selected: #55bb55ff;
$color-web-button-filter-background-hovered: #c6c6c6ff;
$color-web-button-filter-contents-default: #000000ff;
$color-web-button-filter-contents-selected: #ffffffff;
$color-web-button-hero-contents-default: #373737ff;
$color-web-button-hero-contents-hovered: #373737ff;
$color-web-button-hero-background-default: #99d699ff;
$color-web-button-hero-background-hovered: #77c977ff;
$color-web-button-footer-ad-background-default: #ffffffff;
$color-web-button-footer-ad-background-hovered: #feeeccff;
$color-web-button-footer-ad-contents-default: #373737ff;
$color-web-button-log-kcal-contents-default: #000000ff;
$color-web-button-log-kcal-border-default: #99d699ff;
$color-web-button-log-kcal-background-hovered: #ddf1ddff;
$color-web-button-popover-background-default: #ffffffff;
$color-web-button-popover-border-default: #757677ff;
$color-web-button-popover-contents-default: #757677ff;
$color-web-link-default: #168016ff;
$color-web-link-article-default: #168016ff;
$color-web-header-selection-indicator: #ffbb00ff;
$color-web-header-background-default: #ffffffff;
$color-web-header-background-hovered: #f4f4f4ff;
$color-web-header-text-default: #545454ff;
$color-web-header-text-selected: #000000ff;
$color-web-header-icon-default: #000000ff;
$color-web-header-icon-selected: #ffbb00ff;
$color-web-header-new-label-background-default: #ff806cff;
$color-web-header-new-label-contents-default: #ffffffff;
$color-web-footer-social-network-logo-default: #757677ff;
$color-web-search-field-border-default: #b2b2b2ff;
$color-web-footer-ad-background-default: #fedd9aff;
$color-web-footer-ad-text-default: #373737ff;
$color-web-step-header-icon-default: #757677ff;
$color-web-step-header-icon-active: #373737ff;
$color-web-step-header-icon-background-default: #ffffffff;
$color-web-step-header-icon-background-active: #fdd072ff;
$color-web-step-header-icon-border-default: #ffbb00ff;
$color-web-step-header-icon-border-active: #fdd072ff;
$color-web-popover-background-default: #ffffffff;
$color-web-popover-contents-default: #000000ff;
$color-web-popover-header-background-default: #edededff;
$color-web-getting-started-icon-default: #000000ff;
$color-web-getting-started-icon-background-default: #edededff;
$color-web-text-field-share-background: #dff2df80;
$color-web-text-field-validation-background: #fedd9aff;
$color-web-campaign-focus-content: #545454ff;
$color-web-campaign-focus-border: #ffbb00ff;
$color-web-campaign-focus-badge: #feca50ff;
$color-web-campaign-message-background: #fedd9aff;
$color-web-campaign-message-content: #545454ff;
$color-web-illustration-background: #fedd9aff;
$color-web-illustration-content: #545454ff;
$color-web-text-headline: #373737ff;
$color-web-text-primary: #373737ff;
$color-web-text-secondary: #757677ff;
$color-web-option-content-default: #000000ff;
$color-web-option-content-wrong: #ffffffff;
$color-web-option-content-correct: #ffffffff;
$color-web-option-background-default: #99d699ff;
$color-web-option-background-hover: #77c977ff;
$color-web-option-background-wrong: #ce2b1dff;
$color-web-option-background-correct: #55bb55ff;
$color-web-option-background-selected: #55bb55ff;
$color-web-option-background-fact: #ffffffff;
$color-web-option-border-correct: #55bb55ff;
$color-web-option-border-wrong: #ffc7c1ff;
$color-web-option-icon-content-default: #373737ff;
$color-web-option-icon-content-selected: #ffffffff;
$color-web-option-icon-content-correct: #55bb55ff;
$color-web-option-icon-content-wrong: #ce2b1dff;
$color-web-option-icon-background-default: #ffffffff;
$color-web-option-icon-background-selected: #373737ff;
$color-picker-text: #373737ff;
$color-picker-background: #ffbb00ff;
$color-picker-small-int-border: #b2b2b2ff;
$color-picker-calendar-accent: #99d699ff;
$color-picker-calendar-selection-today: #ffffffff;
$color-i-os-tab-background-default: #99d699ff;
$color-i-os-tab-background-selected: #ffffffff;
$color-i-os-tab-contents-default: #373737ff;
$color-i-os-tab-contents-selected: #373737ff;
$color-i-os-switch-track-checked: #55bb55ff;
$color-i-os-switch-track-default: #edededff;
$color-i-os-switch-thumb-checked: #ffffffff;
$color-i-os-switch-thumb-default: #ffffffff;
$color-i-os-tutorial-hint-background: #ffffffff;
$color-i-os-tutorial-hint-contents: #000000ff;
$color-android-top-tab-contents-default: #373737ff;
$color-android-top-tab-contents-selected: #373737ff;
$color-android-top-tab-underline-selected: #ffbb00ff;
$color-android-top-tab-underline-default: #c6c6c6ff;
$color-android-top-tab-background-pressed: #fdd072ff;
$color-android-switch-thumb-checked: #55bb55ff;
$color-android-switch-thumb-default: #ffffffff;
$color-android-switch-track-checked: #88cf88ff;
$color-android-switch-track-default: #757677ff;
$color-android-tutorial-hint-background: #757677ff;
$color-android-popup-accent: #168016ff;
$color-checkbox-accent-checked: #55bb55ff;
$color-checkbox-accent-default: #757677ff;
$color-fast-log-accent: #55bb55ff;
$color-fast-log-background-default-hovered: #55bb5533;
$color-fast-log-background-checked-hovered: #168016ff;
$color-star-rating-default: #ffbb00ff;
$color-star-goal-default: #ffbb00ff;
$color-star-action-default: #55bb55ff;
$color-star-own-rating-inactive: #757677ff;
$color-star-own-rating-default: #55bb55ff;
$color-apps-divider-default: #b2b2b2ff;
$color-apps-divider-medium: #cfcfcfff;
$color-kcal-track-progress-default: #ffbb00ff;
$color-kcal-track-progress-warning: #ff806cff;
$color-kcal-track-background-default: #ffffffff;
$color-kcal-track-background-warning: #fdd072ff;
$color-placeholder-image: #b2b2b2ff;
$color-placeholder-text: #b2b2b2ff;
$color-slider-accent: #55bb55ff;
$color-slider-background-pressed: #55bb5533;
$color-slider-track-active: #55bb55ff;
$color-slider-track-inactive: #d9d9d9ff;
$color-slider-thumb-core: #55bb55ff;
$color-slider-thumb-outer: #ccebccff;
$color-page-indicator-default: #ffffffff;
$color-page-indicator-selected: #ffbb00ff;
$color-page-indicator-logbook-default: #c6c6c6ff;
$color-page-indicator-logbook-selected: #000000ff;
$color-chart-bar-default: #ffbb00ff;
$color-chart-bar-secondary: #c6c6c6ff;
$color-chart-bar-negative: #ff806cff;
$color-chart-bar-tertiary: #000000ff;
$color-chart-marker-background: #fdd072ff;
$color-chart-marker-contents: #000000ff;
$color-screen-scrim-solid: #373737ff;
$color-screen-scrim-translucent: #3737374d;
$color-plus-menu-background-default: #ffbb00ff;
$color-plus-menu-background-pressed: #e4a905ff;
$color-plus-menu-contents-default: #ffffffff;
$color-badge-accent: #ffbb00ff;
$color-badge-text: #373737ff;
$color-tutorial-hint-background: #ffffffff;
$color-tutorial-hint-contents: #000000ff;
$color-swipe-delete-background: #ff806cff;
$color-swipe-delete-contents: #373737ff;
$color-scrim-light-app-bar: linear-gradient(
  to bottom,
  #00000000 0%,
  #00000000 45.83%,
  #00000000 100%
);
$color-info-box-alert-content: #000000ff;
$color-info-box-alert-background: #feeeccff;
$color-info-box-tip-content: #373737ff;
$color-info-box-tip-background: #feeeccff;
$color-info-box-info-content: #373737ff;
$color-info-box-info-background: #feeeccff;
$color-info-box-allergies-contents: #000000ff;
$color-info-box-allergies-background: #f4f4f4ff;
$color-info-box-default-content: #000000ff;
$color-info-box-default-background: #f4f4f4ff;
$color-mood-great-inactive: #3bf98dff;
$color-mood-great-active: #00d85dff;
$color-mood-great-border-default: #168016ff;
$color-mood-great-background-default: #ddf1ddff;
$color-mood-good-inactive: #9cfdb6ff;
$color-mood-good-active: #46fb76ff;
$color-mood-good-border-default: #207474ff;
$color-mood-good-background-default: #d7f4f4ff;
$color-mood-unsure-inactive: #e0f694ff;
$color-mood-unsure-active: #f8e456ff;
$color-mood-unsure-border-default: #966f03ff;
$color-mood-unsure-background-default: #fee6b4ff;
$color-mood-bad-inactive: #fd8081ff;
$color-mood-bad-active: #fd5658ff;
$color-mood-bad-background-default: #ffcccdff;
$color-mood-bad-border-default: #ce2b1dff;
$color-mood-border-disabled: #b2b2b2ff;
$color-mood-background-disabled: #f4f4f4ff;
$color-illustration-small-contents: #545454ff;
$color-illustration-small-background: #fedd9aff;
$color-illustration-large-contents: #545454ff;
$color-illustration-large-background: #fdd072ff;
$color-illustration-inactive-content: #b2b2b2ff;
$color-illustration-inactive-background: #edededff;
$color-card-transparent-stroked-border: #ffffffff;
$color-logged-status-icon-logged-background: #55bb55ff;
$color-logged-status-icon-logged-contents: #ffffffff;
$color-logged-status-icon-empty-border: #55bb55ff;
$color-logged-status-icon-empty-contents: #55bb55ff;
$color-logged-status-icon-empty-background: #00000000;
$color-icon-static: #000000ff;
$color-icon-check: #55bb55ff;
$color-icon-alert: #ce2b1dff;
$color-icon-calendar-content: #ecae03ff;
$color-icon-calendar-background: #ffffffff;
$color-icon-kcal-target-content: #ecae03ff;
$color-icon-kcal-target-background: #ffffffff;
$color-icon-result-favorite: #55bb55ff;
$color-icon-result-generic: #ecae03ff;
$color-icon-result-own: #373737ff;
$color-story-background-primary: #fedd9aff;
$color-story-background-secondary: #c2e6c2ff;
$color-story-icon-do: #55bb55ff;
$color-story-icon-dont: #ff806cff;
$color-graph-bar-default: #fdd072ff;
$color-graph-bar-inactive: #edededff;
$color-graph-bar-protein-default: #88cf88ff;
$color-graph-bar-protein-background: #88cf8826;
$color-graph-bar-carbs-default: #fdd072ff;
$color-graph-bar-carbs-background: #ffbb0033;
$color-graph-bar-fat-default: #ffb160ff;
$color-graph-bar-fat-background: #ff932433;
$color-graph-gradient-start: #ffdd80ff;
$color-graph-gradient-end: #ffffffff;
$color-graph-label-default: #373737ff;
$color-graph-label-protein: #168016ff;
$color-graph-label-fat: #cc761dff;
$color-graph-label-carbs: #e4a905ff;
$color-graph-badge-recommended-content: #ffffffff;
$color-graph-badge-recommended-background: #545454ff;
$color-graph-badge-pointer-background: #fdd072ff;
$color-graph-badge-pointer-content: #373737ff;
$color-graph-badge-warning-content: #ffffffff;
$color-graph-badge-warning-background: #373737ff;
$color-graph-line-default: #ffbb00ff;
$color-graph-line-recommended: #757677ff;
$color-graph-line-warning: #373737ff;
$color-graph-line-y-default: #757677ff;
$color-graph-line-y-carbs: #e4a905ff;
$color-graph-line-y-protein: #168016ff;
$color-graph-line-y-fat: #cc761dff;
$color-graph-marker-content: #000000ff;
$color-graph-marker-background: #f4f4f4ff;
$color-graph-marker-line: #c6c6c6ff;
$color-graph-calendar-label: #757677ff;
$color-graph-calendar-training: #545454ff;
$color-graph-calendar-star: #ecae03ff;
$color-graph-calendar-percent: #000000ff;
$color-graph-calendar-inactive: #b2b2b2ff;
$color-graph-calendar-check: #55bb55ff;
$color-graph-calendar-border: #e4a905ff;
$color-graph-calendar-background-primary: #fff1ccff;
$color-graph-calendar-background-secondary: #f4f4f4ff;
$color-content-card-background-primary: #fedd9aff;
$color-content-card-background-alert: #ffbc96ff;
$color-content-card-content-default: #000000ff;
$color-content-card-icon-alert: #545454ff;
$color-content-card-icon-info: #545454ff;
$color-content-card-icon-background: #ffffffff;
$color-pill-question-icon: #545454ff;
$color-pill-question-text: #373737ff;
$color-pill-question-background: #bbe3bbff;
$color-pill-foodstuff-text: #373737ff;
$color-pill-foodstuff-background: #bbe3bbff;
$color-pill-recipe-text: #373737ff;
$color-pill-recipe-background: #ffffffff;
$color-chat-bubble-bot-icon: #757677ff;
$color-chat-bubble-bot-text: #000000ff;
$color-chat-bubble-bot-background: #ffffffff;
$color-chat-bubble-user-text: #000000ff;
$color-chat-bubble-user-background: #fedd9aff;
$color-chat-prompt-button: #55bb55ff;

// ----------------- temporary local variables
$color-button-modal-header-contents-default: $color-button-primary-contents-default;
$color-button-modal-header-background-hovered: $color-web-pill-tab-background-hovered;
$color-core-green-light-4: #d5ece1ff;
$color-additional-offer-background: $color-core-secondary;
$color-core-primary-light-4: #e0d7ffff;
